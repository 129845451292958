export const AVAILABLE_FACET_TYPES = [
  {
    name: 'institution',
    attribute: 'dataProvider',
  },
  {
    name: 'date',
    attribute: 'dateMin',
    customInterface: 'date',
  },
  {
    name: 'isPartOf',
    attribute: 'isPartOf',
  },
  {
    name: 'subject',
    attribute: 'subject',
  },
  {
    name: 'objectType',
    attribute: 'dcType',
  },
  {
    name: 'medium',
    attribute: 'medium',
  },
  {
    name: 'creator',
    attribute: 'creator',
  },
  {
    name: 'publisher',
    attribute: 'publisher',
    borderAfter: true,
  },
  {
    name: 'rightOfUse',
    attribute: 'edmRightsName',
  },
  {
    name: 'reuse',
    attribute: 'edmRightsReusePolicy',
    locale: 'reuse',
  },
  {
    name: 'mediaType',
    attribute: 'edmType',
    locale: 'mediaType',
  },
]

export const AVAILABLE_SORTINGS = [
  { value: 'chos', label: 'relevance', icon: 'bi-sort-down-alt' },
  {
    value: 'chos/sort/dateMin:asc',
    label: 'date',
    direction: 'asc',
    icon: 'bi-sort-numeric-down',
  },
  {
    value: 'chos/sort/dateMin:desc',
    label: 'date',
    direction: 'desc',
    icon: 'bi-sort-numeric-up',
  },
  {
    value: 'chos/sort/dataProvider:asc',
    label: 'institution',
    direction: 'asc',
    icon: 'bi-sort-alpha-down',
  },
  {
    value: 'chos/sort/dataProvider:desc',
    label: 'institution',
    direction: 'desc',
    icon: 'bi-sort-alpha-up',
  },
  {
    value: 'chos/sort/titleSort:asc',
    label: 'title',
    direction: 'asc',
    icon: 'bi-sort-alpha-down',
  },
  {
    value: 'chos/sort/titleSort:desc',
    label: 'title',
    direction: 'desc',
    icon: 'bi-sort-alpha-up',
  },
]

export const INFO_LIST_CONFIG = [
  {
    title: 'description',
    infos: [{ field: 'description' }],
  },
  {
    title: 'generalData',
    infos: [
      { label: 'title', field: 'title' }, //OK
      { label: 'identifier', field: 'identifier' }, //EDM Kennung
      { label: 'date', field: 'date' },
      { label: 'created', field: 'created' },
      { label: 'isPartOf', field: 'isPartOf' },
      { label: 'subject', field: 'subject' },
      { label: 'objectType', field: 'dcType' },
      { label: 'medium', field: 'medium' },
      { label: 'coverage', field: 'coverage' },
      { label: 'spatialCoverage', field: 'spatial' },
      { label: 'temporalCoverage', field: 'temporal' },
      { label: 'extent', field: 'extent' },
      { label: 'language', field: 'language' },
      { label: 'currentLocation', field: 'currentLocation' },
      { label: 'provenance', field: 'provenance' },
    ],
  },
  {
    title: 'entities',
    infos: [
      { label: 'creator', field: 'creator' },
      { label: 'publisher', field: 'publisher' },
      { label: 'contributor', field: 'contributor' },
    ],
  },
  {
    title: 'mediaData',
    infos: [
      { label: 'rightOfUse', field: 'edmRightsName', link: 'edmRights' },
      { label: 'rightsHolder', field: 'dcRights' },
      {
        label: 'reuse',
        field: 'edmRightsReusePolicy',
        locale: 'reuse',
      },
      { label: 'mediaType', field: 'edmType', locale: 'mediaType' },
    ],
  },
  {
    title: 'origin',
    infos: [{ label: 'providedBy', field: 'intermediateProvider' }],
  },
]

export const PAGE_SIZE = 50

export const DEBOUNCE_MILLISECONDS = 200

export const INDEX_NAME = 'chos'
