import _ from 'lodash'

export const useProviderStore = defineStore('provider', () => {
  /* === init === */
  const config = useRuntimeConfig()
  const { $directus, $readItems } = useNuxtApp()

  /* === state === */
  const providers = ref([])
  const _providers = ref([]) // save unflipped data for internal use

  /* === getters === */
  const providerCount = computed(() => providers.value.length)
  const shuffledProviders = computed(() => {
    const res = _.clone(providers.value)
    return shuffle(res)
  })

  /* === actions === */
  async function initProviders() {
    _providers.value = await $directus().request(
      $readItems('institutions', {
        filter: {
          data_sources: { envs: { _contains: config.public.dataEnv } },
        },
        fields: [
          '*',
          'hero_image.*',
          {
            translations: ['*'],
          },
        ],
        sort: 'name',
      })
    )

    const flippedData = _providers.value.map(flipTranslatedObject)
    providers.value = flippedData
    return flippedData
  }

  function _collectLocalizedSlugs(provider) {
    const slugs = []
    for (const lang in provider.slug) {
      const localizedSlug = provider.slug[lang]
      if (localizedSlug) {
        slugs.push(localizedSlug)
      }
    }
    return slugs
  }

  function getProviderBySlug(slug) {
    /**
     * Finds a provider from the provider list via providing a slug. The localization of the slug does not matter as all localized variants are traversed.
     * The function is implemented as action on purpose: The needed provider list will always already be loaded beforehand via global init middleware.
     */

    return providers.value.find((provider) => {
      return _collectLocalizedSlugs(provider).includes(slug)
    })
  }

  /**
   * Finds a provider from the provider list via providing a name. The name of a provider is a respectively unique unlocalized property.
   * The function is implemented as action on purpose: The needed provider list will always already be loaded beforehand via global init middleware.
   */
  function getProviderByName(name) {
    return providers.value.find((provider) => provider.name === name)
  }

  return {
    // state
    providers,
    _providers,
    // getters
    shuffledProviders,
    providerCount,
    // actions
    initProviders,
    getProviderBySlug,
    getProviderByName,
  }
})
