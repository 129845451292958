import { useStorage } from '@vueuse/core'

export const useFavoritesStore = defineStore('favoritesStore', () => {
  /* === state === */
  const favorites = ref([])

  /* === getters === */
  const favoritesIds = computed(() => favorites.value.map((item) => item.id))

  function setLocalStorage() {
    localStorage.setItem(
      'kulturpool-favorites',
      JSON.stringify(Array.from(favorites.value))
    )
  }

  function toggleFavorite(item) {
    const foundIndex = favorites.value.findIndex(
      (activeFavorite) => activeFavorite.id === item.id
    )
    if (foundIndex === -1) {
      favorites.value.push(item)
    } else {
      favorites.value.splice(foundIndex, 1)
    }
    setLocalStorage()
  }

  function isInFavorites(item) {
    return favoritesIds.value.includes(item.id)
  }

  /* === actions === */

  function initFavorites() {
    const state = useStorage('kulturpool-favorites')
    if (state.value && state.value !== 'undefined') {
      favorites.value = JSON.parse(state.value)
    }
  }

  function clearAllFavorites() {
    favorites.value = []
    setLocalStorage()
  }

  return {
    // state
    favorites,
    // getters
    favoritesIds,
    // actions
    initFavorites,
    toggleFavorite,
    isInFavorites,
    clearAllFavorites,
  }
})
