<script setup>
import 'bootstrap-icons/font/bootstrap-icons.css'
import { onMounted } from 'vue'
/* === i18n === */
const { locale } = useI18n()
const localePath = useLocalePath()

/* === init === */

// global content store make use of i18n in setup context (do not move to middleware)
const globalContentStore = useGlobalContentStore()
const favoritesStore = useFavoritesStore()

await callOnce('navigation-init', globalContentStore.initNavigation)

if (process.client) {
  // local storage is only on client available
  await callOnce('favorites-init', favoritesStore.initFavorites)
}

const config = useRuntimeConfig()

const { homeComponentSummary } = storeToRefs(globalContentStore)

/* === seo === */
useHead({
  titleTemplate: '%s - Kulturpool',
  link: {
    href: new URL(config.public.typesenseClientSideUrl).toString(),
    crossOrigin: true,
    rel: 'preconnect',
  },
  meta: [
    {
      'http-equiv': 'Content-Security-Policy',
      content: 'upgrade-insecure-requests',
    },
  ],

  link: [
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/apple-touch-icon.png',
    },
    {
      rel: 'manifest',
      href: '/site.webmanifest',
    },
    {
      rel: 'mask-icon',
      href: '/safari-pinned-tab.svg',
      color: '#1397f1',
    },
    {
      rel: 'msapplication-TileColor',
      content: '#ffffff',
    },
    {
      rel: 'theme-color',
      content: '#ffffff',
    },
    {
      rel: 'shortcut icon',
      href: '/favicon.ico',
      type: 'image/x-icon',
    },
    {
      rel: 'icon',
      href: '/favicon.ico',
      type: 'image/x-icon',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: '/favicon.png',
    },
  ],
})

useSchemaOrg([
  defineWebSite({
    name: 'Kulturpool',
    url: computed(
      () =>
        'https://kulturpool.at' +
        (locale.value === 'de' ? '' : '/' + locale.value)
    ),
    description: computed(() => homeComponentSummary.value),
    inLanguage: computed(() => locale.value),
    potentialAction: [
      defineSearchAction({
        target: computed(
          () => localePath('search') + '?query={search_term_string}'
        ),
      }),
    ],
  }),
])

/* === color settings === */
useColorScheme()

/* === feedback widget === */
const enableFeedbackWidget = useTestCookieId('marker-io')

/* === development related === */

onMounted(() => {
  console.log(`version: \t\t\t ${config.public.frontendVersion}`)
  console.log(`deployment-tier: \t ${config.public.environmentTier}`)
})
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <ClientOnly>
    <TheFeedbackWidget v-if="enableFeedbackWidget" />
    <CookieBanner />
  </ClientOnly>
</template>
