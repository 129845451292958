import { useSearchStore } from '~/stores/search-store'
import { useWindowSize } from '@vueuse/core'
import { ref, watch } from 'vue'

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}

export function scrollToActiveTile(behavior = 'smooth') {
  const searchStore = useSearchStore()
  if (!searchStore.activeItem) return
  const { windowHeight, headerHeight } = useLayoutDimensions()
  const tile = document.getElementById(searchStore.activeItem?.id)
  const targetHeight =
    tile.offsetTop -
    (windowHeight.value - headerHeight.value) / 2 +
    tile.offsetHeight / 2

  window.scrollTo({
    top: targetHeight,
    behavior: behavior,
  })
}

function useLayoutDimensions() {
  const { width, height } = useWindowSize()

  const windowWidth = width
  const windowHeight = height
  const headerHeight = ref(0)

  watch(
    [width, height],
    () => {
      headerHeight.value = document.querySelector('header').offsetHeight
    },
    { immediate: true }
  )

  return { windowWidth, windowHeight, headerHeight }
}
