import { readItem, readItems } from '@directus/sdk'
import { useDirectusClient } from '../composables/directus'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      directus: (renderingMode) => useDirectusClient(renderingMode),
      readItem,
      readItems,
    },
  }
})
