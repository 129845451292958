import revive_payload_client_4sVQNw7RlN from "/usr/src/kulturpool-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/kulturpool-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/kulturpool-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/usr/src/kulturpool-frontend/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/usr/src/kulturpool-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/kulturpool-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/usr/src/kulturpool-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/kulturpool-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/kulturpool-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/usr/src/kulturpool-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/usr/src/kulturpool-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import directus_BNSudx5Icl from "/usr/src/kulturpool-frontend/plugins/directus.js";
import instantsearch_Us5gXFMlBx from "/usr/src/kulturpool-frontend/plugins/instantsearch.js";
import preview_2IjZF69ftJ from "/usr/src/kulturpool-frontend/plugins/preview.js";
import vue_load_image_ag0x5LX861 from "/usr/src/kulturpool-frontend/plugins/vue-load-image.js";
import vue_matomo_client_u54GK6X55J from "/usr/src/kulturpool-frontend/plugins/vue-matomo.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  directus_BNSudx5Icl,
  instantsearch_Us5gXFMlBx,
  preview_2IjZF69ftJ,
  vue_load_image_ag0x5LX861,
  vue_matomo_client_u54GK6X55J
]