import { flipTranslatedObject } from '../utils/restructure'

export const useSearchStore = defineStore('search', () => {
  /* === init === */
  const { $directus, $readItems } = useNuxtApp()
  const dataEnv = useRuntimeConfig().public.dataEnv

  /* === state === */
  const allDemoSearches = ref([])
  const lastPreviewCho = ref(null)
  const totalHits = ref(0)
  const activeItem = ref(null)
  const renderStatus = ref(null)
  const tilesInView = ref(new Set())
  const currentViewType = ref('tiles')
  const contextField = ref(
    AVAILABLE_FACET_TYPES.find((item) => item.name === 'medium')
  )
  const results = ref(null)
  const hits = ref([])

  /* === getters === */
  const demoSearches = computed(() =>
    shuffle(
      allDemoSearches.value
        .map(flipTranslatedObject)
        .filter((item) => item.in_demo_search === true)
    )
  )
  const isItemActive = computed(() => {
    return activeItem.value !== null
  })
  const lastTileInView = computed(() =>
    tilesInView.value.size === 0
      ? null
      : Math.max(...Array.from(tilesInView.value))
  )

  /* === actions === */
  async function initDemoSearches() {
    try {
      const data = await $directus().request(
        $readItems('demo_searches', {
          filter: {
            envs: { _contains: dataEnv },
          },
          fields: [
            '*',
            {
              translations: ['*'],
            },
          ],
        })
      )
      allDemoSearches.value = data
    } catch (err) {
      console.warn(err)
    }
  }

  function setTotalHits(value) {
    totalHits.value = value
  }

  function setRenderStatus(value) {
    renderStatus.value = value
  }

  function setActionsAfterRender(nextFunction) {
    const stopWatch = watch(renderStatus, (newValue, oldValue) => {
      if (
        (newValue === 'idle' && oldValue === 'loading') ||
        (newValue === 'idle' && oldValue === 'stalled')
      ) {
        setTimeout(() => {
          nextFunction()
          stopWatch()
        }, 10)
      }
    })
  }

  function setActiveItem(item) {
    activeItem.value = item
  }

  function dropActiveItem() {
    lastPreviewCho.value = activeItem.value
    activeItem.value = null
  }

  function toggleViewType() {
    currentViewType.value = currentViewType.value === 'tiles' ? 'grid' : 'tiles'
  }

  return {
    // state
    _demoSearches: allDemoSearches,
    totalHits,
    renderStatus,
    activeItem,
    results,
    hits,
    contextField,
    currentViewType,
    // getters
    lastPreviewCho,
    demoSearches,
    isItemActive,
    tilesInView,
    lastTileInView,
    // actions
    initDemoSearches,
    setTotalHits,
    setRenderStatus,
    setActiveItem,
    dropActiveItem,
    setActionsAfterRender,
    toggleViewType,
  }
})
