const baseVocabulary = {
  collectionObject: 'collection object | collection objects',
  digitisedObject: 'digitised object | digitised objects',
  institution: 'institution | institutions',
}

export default {
  /* === metadata === */
  metadataFields: {
    // === main ===
    institution: baseVocabulary.institution,
    description: 'description',
    // === general data ===
    generalData: 'general data',
    // ===
    title: 'title',
    identifier: 'identifier',
    objectType: 'object type',
    medium: 'medium',
    isPartOf: 'is part of',
    subject: 'subject',
    date: 'date',
    created: 'date (created)',
    coverage: 'coverage',
    spatialCoverage: 'spatial coverage',
    temporalCoverage: 'temporal coverage',
    extent: 'extent',
    currentLocation: 'current location',
    language: 'language',
    provenance: 'provenance',
    // === media ===
    mediaData: 'media data',
    // ===
    rightOfUse: 'usage rights',
    rightsHolder: 'rights holder',
    mediaType: 'media type',
    reuse: 'can I use it?',
    // === entities
    entities: 'persons and entities',
    // ===
    creator: 'creator',
    publisher: 'publisher',
    contributor: 'contributor',
    // === origin ===
    origin: 'origin',
    // ===
    providedBy: 'provided by',
  },
  metadataLocales: {
    reuse: {
      OPEN: 'Yes',
      RESTRICTED: 'Yes, with conditions',
      CLOSED: 'Maybe, with permission',
    },
    mediaType: {
      IMAGE: 'Image',
      TEXT: 'Text',
      VIDEO: 'Video',
      SOUND: 'Sound',
      '3D': '3D',
    },
  },
  /* === components === */
  block: {
    demo_searches: 'Search suggestion',
    more: 'more',
  },
  button: {
    detailPage: 'Full view',
    objectAtInstitution: 'See original',
    share: 'Share',
    shareSuccess: 'Link copied successfully',
    shareError: 'Copying failed',
    downloads: 'Formats',
  },
  collection: {
    objectAtInstitution: 'digitised object at the institution',
    addFavorite: 'Add to favorites',
    dropFavorite: 'Remove from favorites',
    navigateBack: 'Close and navigate to "Home"',
    mediaUnavailable:
      'This digitised object can currently only be played at the partner institution for legal reasons.',
  },
  favorites: {
    title: 'Favorites',
    export: 'Export',
    back: 'Back',
    close: 'Close',
    deleteAll: 'Delete all',
    confirmDeleteAll: 'Confirm deletion',
    openPanel: 'Open favorites panel',
    dropFavorite: 'Remove from favorites',
    columnTitleInstitution: 'Institution',
    columnTitleTitle: 'Title',
    columnTitleInstitutionLink: 'Institution Address',
    columnTitleKulturpoolLink: 'Kulturpool Address',
  },
  filter: {
    applyAll: 'Apply',
    clearAll: 'Clear all filters',
    showAll: 'All filters',
    searchPlaceholder: 'Search for filter',
    noResults: 'No results',
    yearBefore: 'Before',
    yearAfter: 'After',
    deleteFacet: 'Delete filter',
    backToTypes: 'Back to filter types',
    sliderLabel: 'Choose a range of values',
  },
  image: {
    showImageInfo: 'Show image information',
  },
  navigation: {
    searchTitle: 'Search',
    menuTitle: 'Menu',
    hits: 'hits',
    noHits: 'No hits',
    lightColors: 'Light mode',
    darkColors: 'Dark mode',
    autoColors: 'System mode',
    contrastColors: 'Contrast mode',
    scrollForward: 'Scroll digitised objects forward',
    scrollBackward: 'Scroll digitised objects backward',
    closeNavigation: 'Close navigation',
    openNavigation: 'Open navigation',
    closeNavigationButton: 'Close',
    bmkoes:
      'Logo: "Bundesministerium für Kunst, Kultur, öffentlichen Dienst und Sport"',
    eu: 'Logo: "Finanziert von der Europäischen Union"',
    nhm: 'Logo: "Naturhistorisches Museum Wien"',
  },
  preview: {
    previousObject: 'Open preview for previous object',
    nextObject: 'Open preview for previous object',
    setFavorite: 'Add object to favorites',
    removeFavorite: 'Remove object from favorites',
    fullView: 'Open object details page',
    closePreview: 'Close object preview',
  },
  result: {
    loadPrevious: 'Load previous results',
    loadMore: 'Load more',
    context: 'Choose a context field',
  },
  search: {
    inputPlaceholder: `What are you looking for?`,
    resetQuery: 'Clear query input',
    searchAppeal:
      'Use the search and filters above or select one of our sample searches:',
    showMore: `Show more`,
    foundNothing:
      'We could not match any cultural heritage object to your request. ',
    foundNothingAppeal: 'Check your search term and the active filters.',
  },
  sort: {
    sorting: 'Sorting',
    sortedBy: 'Sorted by',
    date: 'Date',
    sortBy: 'Sort by',
    title: 'title',
    relevance: 'relevance',
    institution: 'institution',
    openPanel: 'Open sort panel',
    ascending: 'ascending',
    descending: 'descending',
  },
  swiper: {
    discoverInstitutions: `Discover all institutions`,
  },
  view: {
    switchView: 'Switch view',
    tiles: 'Tiles',
    grid: 'Grid',
  },
  feedback: {
    giveFeedback: 'Give feedback',
    hideButton: 'Hide feedback button',
  },
  cookies: {
    declaration: `This website uses cookies:
    In addition to technically necessary cookies, we also use cookies whose purpose is to analyze website access and cookies that are set by third-party providers such as Sketchfab or Marker.io.
    You can find more information in our {policyLink}.`,
    generalIFrameDeclaration:
      'To display this media content, we use an iFrame that points to an external source. Please allow cookies for the display to work.',
    sketchfabIFrameDeclaration:
      'To view the 3D model of this digitised object, we use an iFrame that points to an external source. Please allow cookies for the display to work.',
    acceptGeneralMediaCookies: 'Accept cookies set by media content',
    acceptSketchfabCookies: 'Accept cookies set by sketchfab',
    acceptAll: 'Accept all cookies',
    acceptNecessary: 'Accept only necessary cookies',
    iFrameDeclaration: 'This cannot continue',
    acceptIframes: 'Accept cookies',
    privacyPolicy: 'privacy policy',
  },
  /* === pages === */
  pages: {
    institutions: {
      pageTitle: 'Institutions',
      institution: baseVocabulary.institution,
      onlineCatalogue: 'Online catalogue',
      toTheDigitisedObjects: `To the digitised objects in Kulturpool`,
    },
  },
  /* === control === */
  errors: {
    backHome: 'Back to Home',
    typesenseMessage:
      'Something went wrong while fetching data from the Kulturpool index.',
    mongodbMessage:
      'Something went wrong while fetching data from the Kulturpool database.',
    directusMessage:
      'Something went wrong while fetching data from the Kulturpool contents.',
    choPageMessage:
      'The object you sought after does not exist in Kulturpool (anymore).',
    contentPageMessage:
      'The page you sought after does not exist in Kulturpool (anymore).',
    showStack: 'Detailed Error Stack ',
  },
  /* === seo === */
  seo: {
    pageTitles: {
      search: 'Search',
      institutions: 'Institutions',
    },
  },
}
