import { computed } from 'vue'

export const useGlobalContentStore = defineStore('globalContent', () => {
  /* === i18n === */
  const { locale } = useI18n()

  /* === init === */
  const { $directus, $readItems } = useNuxtApp()

  const dataEnv = useRuntimeConfig().public.dataEnv

  /* === state === */
  const _globalContent = ref(null)

  /* === getters === */
  const _translatedObject = computed(() => {
    return _globalContent.value?.translations.find(
      (translation) => translation.languages_code === locale.value
    )
  })

  /* --- navigation --- */
  const menuLinks = computed(() => _translatedObject?.value.menu_links || [])
  const footerLinks = computed(
    () => _translatedObject?.value.footer_links || []
  )

  /* --- seo --- */
  const seoSearchDescription = computed(
    () => _translatedObject?.value.search_description || ''
  )
  const seoInstitutionsDescription = computed(
    () => _translatedObject?.value.institutions_description || ''
  )
  /* --- home --- */
  const _homeComponentData = computed(() =>
    _globalContent.value?.home_content.translations.find(
      (translation) => translation.languages_code === locale.value
    )
  )

  const homeComponentId = computed(() => _globalContent.value?.home_content?.id)
  const homeComponentSummary = computed(() => _homeComponentData.value?.summary)

  /* === actions === */
  async function initNavigation() {
    try {
      const data = await $directus().request(
        $readItems('global', {
          filter: {
            envs: { _contains: dataEnv },
          },
          fields: [
            {
              home_content: ['id', { translations: ['*'] }],
            },
            {
              translations: ['*', 'search_seo.*', 'institutions_seo.*'],
            },
          ],
        })
      )
      _globalContent.value = data[0]
    } catch (err) {
      console.warn(err)
    }
  }

  return {
    // state
    // do not remove (this is a pinia state thing for the computed values to work)
    _globalContent,
    _homeComponentData,
    // === getters ===
    // --- navigation ---
    menuLinks,
    footerLinks,
    // --- seo ---
    seoSearchDescription,
    seoInstitutionsDescription,
    // --- home ---
    homeComponentId,
    homeComponentSummary,
    // homeComponentId,
    // actions
    initNavigation,
  }
})
