import { createDirectus, rest } from '@directus/sdk'

let SSRClient
let CSRClient

export function useDirectusClient(renderingMode) {
  const pubConfig = useRuntimeConfig().public

  // keep ordering as server is run first and client second (otherwise you may break conditions)

  if (renderingMode === 'csr' || process.client) {
    if (CSRClient !== undefined) return CSRClient
    CSRClient = createDirectus(pubConfig.directusCsrUrl).with(rest())
    return CSRClient
  }

  if (renderingMode === 'ssr' || process.server) {
    if (SSRClient !== undefined) return SSRClient
    SSRClient = createDirectus(pubConfig.directusSsrUrl).with(rest())
    return SSRClient
  }
}
