
// @ts-nocheck
import locale__usr_src_kulturpool_frontend_locales_de_js from "../locales/de.js";
import locale__usr_src_kulturpool_frontend_locales_en_js from "../locales/en.js";


export const localeCodes =  [
  "de",
  "en"
]

export const localeLoaders = {
  "de": [{ key: "../locales/de.js", load: () => Promise.resolve(locale__usr_src_kulturpool_frontend_locales_de_js), cache: true }],
  "en": [{ key: "../locales/en.js", load: () => Promise.resolve(locale__usr_src_kulturpool_frontend_locales_en_js), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.js?hash=c231e734&config=1" /* webpackChunkName: "__i18n_config_js_c231e734" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.js",
  "locales": [
    {
      "code": "de",
      "iso": "de",
      "dir": "ltr",
      "name": "DE",
      "files": [
        "locales/de.js"
      ]
    },
    {
      "code": "en",
      "iso": "en",
      "dir": "ltr",
      "name": "EN",
      "files": [
        "locales/en.js"
      ]
    }
  ],
  "defaultLocale": "de",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "https://kulturpool.at",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "content/index": {
      "de": "/inhalte",
      "en": "/content"
    },
    "content/[slug]/index": {
      "de": "/inhalte/[slug]",
      "en": "/content/[slug]"
    },
    "content-id/[content-id]": {
      "de": "/inhalt-id/[content-id]",
      "en": "/content-id/[content-id]"
    },
    "search": {
      "de": "/suche",
      "en": "/search"
    },
    "institutions/index": {
      "de": "/institutionen",
      "en": "/institutions"
    },
    "institutions/[provider]/index": {
      "de": "/institutionen/[provider]",
      "en": "/institutions/[provider]"
    },
    "institutions/[provider]/[identifier]": {
      "de": "/institutionen/[provider]/[identifier]",
      "en": "/institutions/[provider]/[identifier]"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "seo": true,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "de",
    "iso": "de",
    "dir": "ltr",
    "name": "DE",
    "files": [
      {
        "path": "locales/de.js"
      }
    ]
  },
  {
    "code": "en",
    "iso": "en",
    "dir": "ltr",
    "name": "EN",
    "files": [
      {
        "path": "locales/en.js"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
