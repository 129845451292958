import { default as _91content_45id_93hZGGKRNctfMeta } from "/usr/src/kulturpool-frontend/pages/content-id/[content-id].vue?macro=true";
import { default as indexOj8ApCubCCMeta } from "/usr/src/kulturpool-frontend/pages/content-id/index.vue?macro=true";
import { default as indexmbs3A4oM7kMeta } from "/usr/src/kulturpool-frontend/pages/content/[slug]/index.vue?macro=true";
import { default as index2nQu8hdfJ1Meta } from "/usr/src/kulturpool-frontend/pages/content/index.vue?macro=true";
import { default as indexioq7jxHFVXMeta } from "/usr/src/kulturpool-frontend/pages/devtools/index.vue?macro=true";
import { default as indexvDW6QBURS9Meta } from "/usr/src/kulturpool-frontend/pages/index.vue?macro=true";
import { default as _91identifier_93JA2rQl6dYyMeta } from "/usr/src/kulturpool-frontend/pages/institutions/[provider]/[identifier].vue?macro=true";
import { default as index7oKNCWrkzeMeta } from "/usr/src/kulturpool-frontend/pages/institutions/[provider]/index.vue?macro=true";
import { default as indexjJYmGLXDW9Meta } from "/usr/src/kulturpool-frontend/pages/institutions/index.vue?macro=true";
import { default as searchCEWJ7kIHjOMeta } from "/usr/src/kulturpool-frontend/pages/search.vue?macro=true";
export default [
  {
    name: _91content_45id_93hZGGKRNctfMeta?.name ?? "content-id-contentid___de",
    path: _91content_45id_93hZGGKRNctfMeta?.path ?? "/inhalt-id/:contentid()",
    meta: _91content_45id_93hZGGKRNctfMeta || {},
    alias: _91content_45id_93hZGGKRNctfMeta?.alias || [],
    redirect: _91content_45id_93hZGGKRNctfMeta?.redirect,
    component: () => import("/usr/src/kulturpool-frontend/pages/content-id/[content-id].vue").then(m => m.default || m)
  },
  {
    name: _91content_45id_93hZGGKRNctfMeta?.name ?? "content-id-contentid___en",
    path: _91content_45id_93hZGGKRNctfMeta?.path ?? "/en/content-id/:contentid()",
    meta: _91content_45id_93hZGGKRNctfMeta || {},
    alias: _91content_45id_93hZGGKRNctfMeta?.alias || [],
    redirect: _91content_45id_93hZGGKRNctfMeta?.redirect,
    component: () => import("/usr/src/kulturpool-frontend/pages/content-id/[content-id].vue").then(m => m.default || m)
  },
  {
    name: indexOj8ApCubCCMeta?.name ?? "content-id___de",
    path: indexOj8ApCubCCMeta?.path ?? "/content-id",
    meta: indexOj8ApCubCCMeta || {},
    alias: indexOj8ApCubCCMeta?.alias || [],
    redirect: indexOj8ApCubCCMeta?.redirect,
    component: () => import("/usr/src/kulturpool-frontend/pages/content-id/index.vue").then(m => m.default || m)
  },
  {
    name: indexOj8ApCubCCMeta?.name ?? "content-id___en",
    path: indexOj8ApCubCCMeta?.path ?? "/en/content-id",
    meta: indexOj8ApCubCCMeta || {},
    alias: indexOj8ApCubCCMeta?.alias || [],
    redirect: indexOj8ApCubCCMeta?.redirect,
    component: () => import("/usr/src/kulturpool-frontend/pages/content-id/index.vue").then(m => m.default || m)
  },
  {
    name: indexmbs3A4oM7kMeta?.name ?? "content-slug___de",
    path: indexmbs3A4oM7kMeta?.path ?? "/inhalte/:slug()",
    meta: indexmbs3A4oM7kMeta || {},
    alias: indexmbs3A4oM7kMeta?.alias || [],
    redirect: indexmbs3A4oM7kMeta?.redirect,
    component: () => import("/usr/src/kulturpool-frontend/pages/content/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexmbs3A4oM7kMeta?.name ?? "content-slug___en",
    path: indexmbs3A4oM7kMeta?.path ?? "/en/content/:slug()",
    meta: indexmbs3A4oM7kMeta || {},
    alias: indexmbs3A4oM7kMeta?.alias || [],
    redirect: indexmbs3A4oM7kMeta?.redirect,
    component: () => import("/usr/src/kulturpool-frontend/pages/content/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index2nQu8hdfJ1Meta?.name ?? "content___de",
    path: index2nQu8hdfJ1Meta?.path ?? "/inhalte",
    meta: index2nQu8hdfJ1Meta || {},
    alias: index2nQu8hdfJ1Meta?.alias || [],
    redirect: index2nQu8hdfJ1Meta?.redirect,
    component: () => import("/usr/src/kulturpool-frontend/pages/content/index.vue").then(m => m.default || m)
  },
  {
    name: index2nQu8hdfJ1Meta?.name ?? "content___en",
    path: index2nQu8hdfJ1Meta?.path ?? "/en/content",
    meta: index2nQu8hdfJ1Meta || {},
    alias: index2nQu8hdfJ1Meta?.alias || [],
    redirect: index2nQu8hdfJ1Meta?.redirect,
    component: () => import("/usr/src/kulturpool-frontend/pages/content/index.vue").then(m => m.default || m)
  },
  {
    name: indexioq7jxHFVXMeta?.name ?? "devtools___de",
    path: indexioq7jxHFVXMeta?.path ?? "/devtools",
    meta: indexioq7jxHFVXMeta || {},
    alias: indexioq7jxHFVXMeta?.alias || [],
    redirect: indexioq7jxHFVXMeta?.redirect,
    component: () => import("/usr/src/kulturpool-frontend/pages/devtools/index.vue").then(m => m.default || m)
  },
  {
    name: indexioq7jxHFVXMeta?.name ?? "devtools___en",
    path: indexioq7jxHFVXMeta?.path ?? "/en/devtools",
    meta: indexioq7jxHFVXMeta || {},
    alias: indexioq7jxHFVXMeta?.alias || [],
    redirect: indexioq7jxHFVXMeta?.redirect,
    component: () => import("/usr/src/kulturpool-frontend/pages/devtools/index.vue").then(m => m.default || m)
  },
  {
    name: indexvDW6QBURS9Meta?.name ?? "index___de",
    path: indexvDW6QBURS9Meta?.path ?? "/",
    meta: indexvDW6QBURS9Meta || {},
    alias: indexvDW6QBURS9Meta?.alias || [],
    redirect: indexvDW6QBURS9Meta?.redirect,
    component: () => import("/usr/src/kulturpool-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvDW6QBURS9Meta?.name ?? "index___en",
    path: indexvDW6QBURS9Meta?.path ?? "/en",
    meta: indexvDW6QBURS9Meta || {},
    alias: indexvDW6QBURS9Meta?.alias || [],
    redirect: indexvDW6QBURS9Meta?.redirect,
    component: () => import("/usr/src/kulturpool-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93JA2rQl6dYyMeta?.name ?? "institutions-provider-identifier___de",
    path: _91identifier_93JA2rQl6dYyMeta?.path ?? "/institutionen/:provider()/:identifier()",
    meta: _91identifier_93JA2rQl6dYyMeta || {},
    alias: _91identifier_93JA2rQl6dYyMeta?.alias || [],
    redirect: _91identifier_93JA2rQl6dYyMeta?.redirect,
    component: () => import("/usr/src/kulturpool-frontend/pages/institutions/[provider]/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93JA2rQl6dYyMeta?.name ?? "institutions-provider-identifier___en",
    path: _91identifier_93JA2rQl6dYyMeta?.path ?? "/en/institutions/:provider()/:identifier()",
    meta: _91identifier_93JA2rQl6dYyMeta || {},
    alias: _91identifier_93JA2rQl6dYyMeta?.alias || [],
    redirect: _91identifier_93JA2rQl6dYyMeta?.redirect,
    component: () => import("/usr/src/kulturpool-frontend/pages/institutions/[provider]/[identifier].vue").then(m => m.default || m)
  },
  {
    name: index7oKNCWrkzeMeta?.name ?? "institutions-provider___de",
    path: index7oKNCWrkzeMeta?.path ?? "/institutionen/:provider()",
    meta: index7oKNCWrkzeMeta || {},
    alias: index7oKNCWrkzeMeta?.alias || [],
    redirect: index7oKNCWrkzeMeta?.redirect,
    component: () => import("/usr/src/kulturpool-frontend/pages/institutions/[provider]/index.vue").then(m => m.default || m)
  },
  {
    name: index7oKNCWrkzeMeta?.name ?? "institutions-provider___en",
    path: index7oKNCWrkzeMeta?.path ?? "/en/institutions/:provider()",
    meta: index7oKNCWrkzeMeta || {},
    alias: index7oKNCWrkzeMeta?.alias || [],
    redirect: index7oKNCWrkzeMeta?.redirect,
    component: () => import("/usr/src/kulturpool-frontend/pages/institutions/[provider]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjJYmGLXDW9Meta?.name ?? "institutions___de",
    path: indexjJYmGLXDW9Meta?.path ?? "/institutionen",
    meta: indexjJYmGLXDW9Meta || {},
    alias: indexjJYmGLXDW9Meta?.alias || [],
    redirect: indexjJYmGLXDW9Meta?.redirect,
    component: () => import("/usr/src/kulturpool-frontend/pages/institutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexjJYmGLXDW9Meta?.name ?? "institutions___en",
    path: indexjJYmGLXDW9Meta?.path ?? "/en/institutions",
    meta: indexjJYmGLXDW9Meta || {},
    alias: indexjJYmGLXDW9Meta?.alias || [],
    redirect: indexjJYmGLXDW9Meta?.redirect,
    component: () => import("/usr/src/kulturpool-frontend/pages/institutions/index.vue").then(m => m.default || m)
  },
  {
    name: searchCEWJ7kIHjOMeta?.name ?? "search___de",
    path: searchCEWJ7kIHjOMeta?.path ?? "/suche",
    meta: searchCEWJ7kIHjOMeta || {},
    alias: searchCEWJ7kIHjOMeta?.alias || [],
    redirect: searchCEWJ7kIHjOMeta?.redirect,
    component: () => import("/usr/src/kulturpool-frontend/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchCEWJ7kIHjOMeta?.name ?? "search___en",
    path: searchCEWJ7kIHjOMeta?.path ?? "/en/search",
    meta: searchCEWJ7kIHjOMeta || {},
    alias: searchCEWJ7kIHjOMeta?.alias || [],
    redirect: searchCEWJ7kIHjOMeta?.redirect,
    component: () => import("/usr/src/kulturpool-frontend/pages/search.vue").then(m => m.default || m)
  }
]